export default async function ({ store, redirect, route, $auth }) {
    const securedPaths = ["/maintenance", "/maintenance/"]
    if (!securedPaths.includes(route.path)) {
        if (!store.state.profile.underMaintenance.checked) {
            await store.dispatch("profile/getMaintenance")
        }
        if (store.state.profile.underMaintenance.value) {
            redirect("/maintenance")
        }
    }
    const securedWalletPaths = [
        "/wallet",
        "/wallet/",
        "/wallet/deposit",
        "/wallet/deposit/",
        "/wallet/withdrawal",
        "/wallet/withdrawal/"
    ]
    if (securedWalletPaths.includes(route.path)) {
        if (!store.state.profile.walletUnderMaintenance.checked) {
            await store.dispatch("profile/getMaintenance")
        }
        if (store.state.profile.walletUnderMaintenance.value) {
            store.commit("profile/set_showMaintenanceModal", true)
            redirect("/")
        }
    }
    const onOffWalletPaths = ["/wallet/deposit", "/wallet/deposit/"]
    const onOffWithdrawalPaths = ["/wallet/withdrawal", "/wallet/withdrawal/"]
    if (onOffWalletPaths.includes(route.path)) {
        // if (!store.state.user.whatsappData) {
        await store.dispatch("user/getconfigs")
        // }
        if (
            store.state.user &&
            store.state.user.whatsappData &&
            store.state.user.whatsappData &&
            !store.state.user.whatsappData.isDepositBtnOn &&
            $auth.loggedIn
        ) {
            redirect("/wallet")
        }
    } else if (onOffWithdrawalPaths.includes(route.path)) {
        // if (!store.state.user.whatsappData) {
        await store.dispatch("user/getconfigs")
        // }
        if (
            store.state.user &&
            store.state.user.whatsappData &&
            store.state.user.whatsappData &&
            !store.state.user.whatsappData.isWithdrawBtnOn &&
            $auth.loggedIn
        ) {
            redirect("/wallet")
        }
    }
    if (route && route.name && route.name.startsWith("fantasybook")) {
        await store.dispatch("user/getFantasyConfigs")
        if (store.state.user && !store.state.user.fantasyConfig) {
            redirect("/")
        }
    }
    // protect route for bonus page if bonus settings are off
    const bonusUrls = [
        "/pending-bonus",
        "/new-pending-bonus",
        "/new-pending-sportbonus"
    ]
    if (bonusUrls.includes(route.path)) {
        await store.dispatch("user/getWhatAppDepositSetting")
        bonusUrls.find((urls) => {
            if (
                (route.path === "/pending-bonus" ||
                    route.path === "/pending-bonus/") &&
                store.state.user &&
                store.state.user.configurationData &&
                !store.state.user.configurationData.isNormalBonusWalletOn
            ) {
                return redirect("/")
            } else if (
                route.path === "/new-pending-sportbonus" &&
                store.state.user &&
                store.state.user.configurationData &&
                !store.state.user.configurationData.isSportBonusWalletOn
            ) {
                return redirect("/")
            } else if (
                route.path === "/new-pending-bonus" &&
                store.state.user &&
                store.state.user.configurationData &&
                !store.state.user.configurationData.isBonusWalletOn
            ) {
                return redirect("/")
            }
            return null
        })
    }
}
