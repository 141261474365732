import service from "../services/apiService"

export const state = () => ({
    userBalanceData: {},
    netExposureData: {},
    newData: {},
    userPendingBonusSum: 0,
    loading: false,
    error: {},
    modalShow: false
})

export const mutations = {
    set_data_new(state, data) {
        state.userBalanceData = data
        state.netExposureData = Object.assign(state.netExposureData, data)
    },
    set_data(state, data) {
        if (data.value) {
            state.userBalanceData = data.data
        } else {
            state.userBalanceData = {}
        }
    },
    set_data_netexposure(state, data) {
        if (data.value) {
            state.netExposureData = data.data
        } else {
            state.netExposureData = {}
        }
    },
    set_data_pendingbonussum(state, data) {
        if (data) {
            state.userPendingBonusSum = data
        } else {
            state.userPendingBonusSum = 0
        }
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    sessionModal_open(state, data) {
        state.modalShow = true
    },
    sessionModal_close(state) {
        state.modalShow = false
    }
}

export const actions = {
    async getCurrentBalance({ commit, dispatch }) {
        try {
            commit("set_loading", true)
            const accessToken = localStorage.getItem("accessTokenNewWallet")
            if (accessToken) {
                const parsedAccessToken = JSON.parse(accessToken)
                const promises = []
                if (parsedAccessToken.accessToken) {
                    promises.push(
                        dispatch(
                            "getCurrentBalanceForNormal",
                            parsedAccessToken
                        )
                    )
                }
                if (parsedAccessToken.accessToken_bonus) {
                    promises.push(
                        dispatch("getCurrentBalanceForBonus", parsedAccessToken)
                    )
                }
                if (parsedAccessToken.accessToken_sp) {
                    promises.push(
                        dispatch("getCurrentBalanceForSP", parsedAccessToken)
                    )
                }
                const results = await Promise.all(promises)
                const combinedData = results.reduce((acc, result) => {
                    return { ...acc, ...result }
                }, {})
                if (Object.keys(combinedData).length > 0) {
                    await commit("set_data_new", combinedData)
                }
            } else {
                commit("set_data", {})
                commit("set_data_netexposure", {})
            }
            // if (
            //     jsonToken &&
            //     jsonToken.accessToken_bonus &&
            //     jsonToken.accessToken_sp &&
            //     jsonToken.accessToken
            // ) {
            //     const response = await this.$axios({
            //         method: "post",
            //         baseURL: process.env.USERS_API_URL,
            //         url: "sportsbook/getCurrentBalanceForSportsBook",
            //         data: {
            //             _accessToken: jsonToken.accessToken
            //         }
            //     })
            //     if (response && response.status === 200 && response.data) {
            //         if (!localStorage.getItem("tripleWalletType")) {
            //             jsonToken = Object.assign(
            //                 jsonToken,
            //                 response.data.data
            //             )
            //         }

            //         jsonToken.main_netExposure =
            //             response.data.data.netExposure
            //         jsonToken.main_balance = response.data.data.balance
            //         await dispatch("getCurrentBalanceForBonus", jsonToken)
            //     }
            // }
            // } else {
            //     commit("set_data", {})
            //     commit("set_data_netexposure", {})
            //     // commit("set_data", response.data)
            //     // commit("set_data_netexposure", response.data)
            // }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getCurrentBalanceForNormal({ commit, dispatch }, jsonToken) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "sportsbook/getCurrentBalanceForSportsBook",
                data: {
                    _accessToken: jsonToken.accessToken
                }
            })
            if (
                response &&
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                if (!localStorage.getItem("tripleWalletType")) {
                    jsonToken = Object.assign(jsonToken, response.data.data)
                }
                jsonToken.main_netExposure = response.data.data.netExposure
                jsonToken.main_balance = response.data.data.balance
                // await dispatch("getCurrentBalanceForSP", jsonToken)
                return jsonToken
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getCurrentBalanceForBonus({ commit, dispatch }, jsonToken) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "sportsbook/getCurrentBalanceForSportsBook",
                data: {
                    _accessToken: jsonToken.accessToken_bonus
                }
            })
            if (
                response &&
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                if (
                    localStorage.getItem("tripleWalletType") &&
                    localStorage.getItem("tripleWalletType") === "Bonus"
                ) {
                    jsonToken = Object.assign(jsonToken, response.data.data)
                }
                jsonToken.bonus_netExposure = response.data.data.netExposure
                jsonToken.bonus_balance = response.data.data.balance
                // await dispatch("getCurrentBalanceForSP", jsonToken)
                return jsonToken
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getCurrentBalanceForSP({ commit, state }, jsonToken) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "sportsbook/getCurrentBalanceForSportsBook",
                data: {
                    _accessToken: jsonToken.accessToken_sp
                }
            })
            if (
                response &&
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                if (
                    localStorage.getItem("tripleWalletType") &&
                    localStorage.getItem("tripleWalletType") === "SportBonus"
                ) {
                    jsonToken = Object.assign(jsonToken, response.data.data)
                }
                jsonToken.sp_netExposure = response.data.data.netExposure
                jsonToken.sp_balance = response.data.data.balance
                return jsonToken
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    async memberAvgBalance({ commit }, playId) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USERS_API_URL,
                url: "member/memberAvgBalanceNew",
                data: {
                    _accessToken: this.$auth.getToken("customLocal"),
                    memberId: playId.memberId
                }
            })
            commit("set_loading", false)
            return response
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
            return error
        }
    },

    async getEligibleInterestBonus({ commit }) {
        try {
            return await service.apiCallWithHeaderUsingGet("/v2/getavgbonus")
        } catch (error) {
            commit("set_apiResponse", {
                response: "error",
                message: error.message || "No Internet Connection"
            })
        }
    },

    async getMemberNetExposure({ commit }) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.USERS_API_URL,
                url: "netExposure/getMemberNetExposure",
                data: {
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })
            commit("set_data_netexposure", response.data)
            commit("set_loading", false)
        } catch (error) {
            // this.dispatch('user/logout')
            // this.commit('snackbar/open', {
            //   text: 'Your session has ended, please login.',
            //   color: 'error'
            // })
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    async getPendingBonusSum({ commit }) {
        try {
            commit("set_loading", true)

            const response = await service.apiCallWithHeader(
                "/v2/User/getUserPendingBonusSum"
            )

            commit("set_loading", false)

            if (
                response &&
                response.status &&
                response.status === 200 &&
                response.data &&
                response.data.data &&
                response.data.data.pendingBonusSum &&
                response.data.data.pendingBonusSum.amount
            ) {
                commit(
                    "set_data_pendingbonussum",
                    response.data.data.pendingBonusSum.amount
                )
            } else {
                commit("set_data_pendingbonussum", 0)
            }
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async saveUser({ commit }, { deviceID, errorLogs }) {
        console.log("deviceID", errorLogs)
        let deviceData = {}
        if (errorLogs) {
            deviceData = { deviceId: errorLogs }
        } else {
            deviceData = { deviceId: deviceID }
        }
        try {
            commit("set_loading", true)
            await this.$axios({
                method: "post",
                baseURL: process.env.USER_V2_API_URL,
                url: "/Memberdevice/saveDeviceId",
                data: deviceData
            })
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
