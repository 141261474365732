export default ({ store, route }) => {
    if (process.env.setUp === "fps" || route.path === "/user-book") return
    if (window) {
        // chat
        setTimeout(() => {
            if (process.env.APP_TITLE && process.env.APP_TITLE === "SuperWin") {
                ;(function (d, w, c) {
                    if (!d.getElementById("spd-busns-spt")) {
                        const n = d.getElementsByTagName("script")[0]
                        const s = d.createElement("script")
                        let loaded = false
                        s.id = "spd-busns-spt"
                        s.async = "async"
                        s.setAttribute("data-self-init", "false")
                        s.setAttribute("data-init-type", "opt")
                        s.src =
                            "https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js"
                        s.setAttribute(
                            "data-client",
                            "1222081f992e3fb70a8ff643142db5a2566bab6d"
                        )
                        s.setAttribute(
                            "data-bot-hash",
                            "787ddfcaf827e893c28def0b29aea14020143e1a"
                        )
                        s.setAttribute("data-env", "prod")
                        s.setAttribute("data-region", "in")
                        if (c) {
                            s.onreadystatechange = s.onload = function () {
                                if (!loaded) {
                                    c()
                                }
                                loaded = true
                            }
                        }
                        n.parentNode.insertBefore(s, n)
                    }
                })(document, window, function () {
                    window.Freshbots.initiateWidget()
                })
            } else {
                ;(function (d, w, c) {
                    if (!d.getElementById("spd-busns-spt")) {
                        const n = d.getElementsByTagName("script")[0]
                        const s = d.createElement("script")
                        let loaded = false
                        s.id = "spd-busns-spt"
                        s.async = "async"
                        s.setAttribute("data-self-init", "false")
                        s.setAttribute("data-init-type", "opt")
                        s.src =
                            "https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js"
                        s.setAttribute(
                            "data-client",
                            "1222081f992e3fb70a8ff643142db5a2566bab6d"
                        )
                        s.setAttribute(
                            "data-bot-hash",
                            "aa6ee4cdf88ba3b9cc7f4a63e40e0673df227ed0"
                        )
                        s.setAttribute("data-env", "prod")
                        s.setAttribute("data-region", "in")
                        if (c) {
                            s.onreadystatechange = s.onload = function () {
                                if (!loaded) {
                                    c()
                                }
                                loaded = true
                            }
                        }
                        n.parentNode.insertBefore(s, n)
                    }
                })(document, window, function () {
                    window.Freshbots.initiateWidget()
                })
            }
        }, 7000)
    }
}
